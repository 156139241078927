<template>
  <card class='px-4 shadow-none w-100' style='background: transparent !important;'>
    <div class="center  ">
      <vs-checkbox class=" " v-model="all">
          Mostrar todas las cuentas
        </vs-checkbox>
      <div class='d-flex  align-items-center '>
        <b-form-select class="justify-content-start"  style='width: 4rem;' placeholder='-- 10 --' :options="options" v-model='max' required
          value='10'>
        </b-form-select>
        
        
        <vs-input  class="w-25 pl-2 ml-auto" v-model="search" border placeholder="Search" />
          
      </div>
       
      
      <!-- <vs-input v-model="search" border placeholder="Search" /> -->
      <div class="table-responsive mt-3">
        <table class="table table-bordered table-responsive-md table-striped text-center">
          <thead>
            <tr>
              <th style='min-width: 8rem; cursor: pointer;' @click.prevent='ordenar("CodigoCompleto")'>
                Codigo
              </th>
              <th style='min-width: 10rem; cursor: pointer;' @click.prevent='ordenar("NombreSubCuenta")'>
                Nombre
              </th>
              <th style='min-width: 10rem; cursor: pointer;' @click.prevent='ordenar("nombreGrupo")'>
                Grupo
              </th>
              <th style='min-width: 10rem; cursor: pointer;' @click.prevent='ordenar("nombreCuenta")'>
                Cuenta
              </th>
              <th style='min-width: 10rem; cursor: pointer;' @click.prevent='ordenar("defecto")'>
                Tipo
              </th>
              <th style='min-width: 10rem; cursor: pointer;' @click.prevent='ordenar("saldoCuenta")'>
                Saldo
              </th>
              <th style='max-width: 8rem'>
                Opciones
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :key="i" v-for="(tr, i) in datos" not-click-selected open-expand-only-td>
              <td>
                {{ tr.CodigoCompleto }}
              </td>
              <td>
                {{ tr.NombreSubCuenta }}
              </td>
              <td>
                {{ tr.nombreGrupo }}
              </td>
              <td>
                {{ tr.nombreCuenta }}
              </td>
              <td>
                {{ tr.defecto }}
              </td>
              <td>
                {{ tr.saldoCuenta }}
              </td>
              <td>
                <div class='d-flex justify-content-center'   >
                  <vs-button v-b-tooltip.hover title='ver' success icon class=' '
                    @click="$router.push(`DetallesCuenta/${tr.Id}`)">
                    <i class="fa-solid fa-eye"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title='ver' :disabled='tr.defecto === "Estandar" ? true : false' icon class=' '
                  @click="$router.push(`EditarCuenta/${tr.Id}`)">
                    <i class="fa-solid fa-pen"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title='ver' danger icon class=' '
                  @click.prevent='deleteCuenta(tr.Id)' :disabled='tr.defecto === "Estandar" ? true : false'>
                    <i class="fa-solid fa-trash"></i>
                  </vs-button>
                  <!-- <b-button class='bg-primary border-0' :disabled='tr.defecto === "Estandar" ? true : false'
                    v-b-tooltip.hover title='Editar' @click="$router.push(`EditarCuenta/${tr.Id}`)">
                    <ion-icon name="create-outline"></ion-icon>
                  </b-button>
                  <b-button @click.prevent='deleteCuenta(tr.Id)' :disabled='tr.defecto === "Estandar" ? true : false'
                    class='bg-danger border-0' v-b-tooltip.hover title='Eliminar'>
                    <ion-icon name="trash-outline"></ion-icon>
                  </b-button> -->
                </div>
              </td>
            </tr>
            <tr v-if='loading' style='height: 10rem;'>
              <td colspan='7' class='w-100'>Buscando registros</td>
            </tr>
            <tr v-else-if='datos.length === 0' style='height: 10rem;'>
              <td colspan='7' class='w-100'>No hay Registros</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class='d-flex justify-content-end align-items-center w-100'>
        <!-- <b-form-select style='width: 4rem;' placeholder='-- 10 --' :options="options" v-model='max' required value='10'>
        </b-form-select> -->
        <!-- <vs-pagination infinite v-model="page" :length="totalpagues" /> -->
        <b-pagination v-model="page" :total-rows="totalpagues" :per-page="1"
          aria-controls="my-table"></b-pagination>
      </div>
    </div>
  </card>
</template>

<script>
  import Swal from 'sweetalert2';
  // import { debounce } from 'lodash/debounce';
  import { core } from '../../../../../../config/pluginInit';

  export default {
    props: {
      clase: {
        type: String,
        required: true
      }
    },
    data: () => ({
      PersonaId: null,
      search: '',
      page: 1,
      totalpagues: 0,
      max: 10,
      loading: false,
      columna: "",
      ascendente: true,
      all: true,
      options: [
        {
          value: 5,
          text: '5'
        },
        {
          value: 10,
          text: '10'
        }, {
          value: 20,
          text: '20'
        }, {
          value: 50,
          text: '50'
        }, {
          value: 100,
          text: '100'
        }
      ],
      datos: []
    }),
    async mounted() {
      const Persona = JSON.parse(localStorage.getItem('credenciales'));
      this.PersonaId = Persona.personaId;

      core.index();
      await this.getData();
    },
    watch: {
      // search: {
      //   handler: debounce(function () {
      //     this.getData();
      //   }, 700),
      //   immediate: false
      // },
      search() {
        this.getData();
      },
      max() {
        this.getData();
      },
      page() {
        this.getData();
      },
      totalpagues() {
        this.getData();
      },
      columna() {
        this.getData();
      },
      ascendente() {
        this.getData();
      },
      all() {
        this.getData();
      }
    },
    methods: {
      async deleteCuenta(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar este Movimiento?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              let response = await this.$store.dispatch("hl_delete", {
                path: `DESubCuenta/EliminarSubCuenta/${id}/personaId/${this.PersonaId}`,
              })
              if (response.respuesta) {
                await this.getData();
                Swal.fire("Listo!", "Cuenta eliminada correctamente.", "success");
              } else if (response.respuesta == false) {
                Swal.fire("Error", "la cuenta tiene movimientos asociados", "error");
              }
            } catch (error) {
              Swal.fire("Error", "Hubo un problema al eliminar la cuenta.", "error");
            }
          }
        });
      },
      async getData() {
        this.datos = [];
        let send = {
          filtro: this.search,
          orden: {
            columna: this.columna,
            ascendente: this.ascendente
          },
          cantDatos: this.max,
          paginacion: this.page
        }
        try {
          this.loading = true;
          let cantidadDatos = await this.$store.dispatch("hl_get", {
            path: `DESubCuenta/consultarCantidadSubCuentasEmpresa/${this.PersonaId}?tipo=${this.clase}&filtro=${this.search}&All=${this.all}`,
          });

          this.totalpagues = Math.ceil(cantidadDatos.data / this.max);
          if (cantidadDatos.data <= this.max) {
            this.page = 1;
          }

          let response = await this.$store.getters.fetchPost({
            path: `DESubCuenta/ObtenerSubCuentasEmpresa/${this.PersonaId}?tipo=${this.clase}&All=${this.all}`,
            data: send
          });
          if (response.ok) {
            const data = await response.json();
            if (data.data.length > 0) {
              this.datos = data.data.map(({ Id, CodigoCompleto, NombreSubCuenta, nombreGrupo, nombreCuenta, defecto, saldoCuenta }) => ({
                Id,
                CodigoCompleto,
                NombreSubCuenta,
                nombreGrupo,
                nombreCuenta,
                defecto: defecto ? 'Estandar' : 'Creada',
                saldoCuenta
              }))
            } else {
              this.response = [];
            }
          } else {
            console.error('Error en la solicitud:', response.statusText);
          }
        } catch (error) {
          this.response = [];
        } finally {
          this.loading = false;
        }
      },
      ordenar(columna) {
        if (this.columna === columna) {
          this.ascendente = !this.ascendente;
        } else {
          this.columna = columna;
          this.ascendente = true;
        }
        this.getData();
      }
    }
  }
</script>

<style>
  .vs-input {
    width: 100%;
  }
</style>